body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
div {
  margin: 0;
}

.ant-pro-table-alert-container {
  display: none;
}

.ant-pro-table {
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 10px;
}

.ant-table-wrapper .ant-table {
  scrollbar-color: inherit !important;
}

.expanded {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: white;
  overflow: hidden;
}

.expanded .ant-tabs-extra-content {
  width: 50%;
}

.tabcontainer .ant-tabs-extra-content {
  width: 50%;
}
